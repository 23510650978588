import { Box, Button, Flex, Heading, Highlight, Img, SimpleGrid, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
import * as Logos from './Brands'
import DemoForm from './DemoForm'

export default function Hero() {
  return (
    <Box as='section' bg={mode('gray.50', 'gray.800')} pt='24' pb='12' overflow='hidden'>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }}>
        <Flex align='flex-start' direction={{ base: 'column', lg: 'row' }} justify='space-between' mb='20'>
          <Box flex='1' maxW={{lg: 'xl'}} pt='6'>

            <Heading as='h1' size='3xl' mt='8' fontWeight='extrabold'>
            <Highlight query='fund raising' styles={{bg:'green.300'}}>
              A modern solution to fund raising & donor management
            </Highlight>
            </Heading>
            <Text color={mode('gray.600', 'gray.400')} mt='5' fontSize='xl'>
              A CRM tool that’s easy to use and seriously smart. Packed with powerful features to speed up your work.
            </Text>
            {/* <Button mt='8' minW='14rem' colorScheme='pink' size='lg' height='14' px='8' fontSize='md' fontWeight='bold'>
              Request a Demo
            </Button> */}
            <DemoForm />
          </Box>
          <Box boxSize={{ base: '20', lg: '8' }} />
          <Img
            pos='relative'
            marginEnd='-16rem'
            borderRadius={10}
            w='50rem'
            src='https://res.cloudinary.com/dilbpof6u/image/upload/v1661963887/Home_Tab_ls3ewu.png'
            alt='Screenshot for Form builder'
          />
        </Flex>
        <Box>
          <Text color={mode('gray.600', 'gray.400')} fontWeight='medium'>
            Proudly trusted by people from 50+ non-profit organizations
          </Text>
          <SimpleGrid
            mt='8'
            columns={{ base: 2, md: 3, lg: 6 }}
            color='gray.500'
            alignItems='center'
            spacing={{ base: '12', lg: '24' }}
            fontSize='2xl'
          >
            <Logos.ChatMonkey />
            <Logos.Wakanda />
            <Logos.Lighthouse />
            <Logos.Plumtic />
            <Logos.WorkScout />
            <Logos.Finnik />
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}
