import { Box, Button, Center, Flex, Heading, Img, SimpleGrid, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export default function Audience() {
  return (
    <>
      <Center>
        <Heading alignContent={'center'} py={10}>
          Designed for Organizations and Individuals
        </Heading>
      </Center>
      <Box>
        <Flex
          align='flex-start'
          direction={{ base: 'column', lg: 'row' }}
          justify='space-between'
          mb='20'
          maxW={{ base: 'xl', md: '7xl' }}
          mx='auto'
          px={{ base: '6', md: '8' }}
        >
          <Box flex='1' borderRadius={10} p={10} m={5} bg={mode('cyan.100', 'teal.700')} shadow='md'>
            <Heading alignContent={'center'} pt={2} pb={5}>
              For Organizations
            </Heading>
            Want missionbase for everyone at your orgaization? Get in touch to see how we can integrate with your office to help you serve your people more effectively. We can connect with your existing software and databases and enable your data to show up for members of your organization, securely.
          </Box>
          <Box flex='1' borderRadius={10} p={10} m={5} bg={mode('gray.100', 'gray.700')} shadow='md'>
            <Heading alignContent={'center'} pt={2} pb={5}>
              For Individuals
            </Heading>
            Is your organization not yet set up wtih missionbase? Find other users from your organization that are using missionbase and join together to collaborate by sharing tags and other data groups relevant to you! Or work together with a friend from another organization!
          </Box>
        </Flex>
      </Box>
    </>
  )
}
