import { Box, Center, Heading, Text, Flex, Button, Input, Stack, Spacer, Container } from '@chakra-ui/react'
import Link from 'next/link'

import Header from '@Marketing/Header'
// import Features from './FeatureBullets'
import Hero from './Hero'
import { FeatureList } from './FeatureList'
import { Features, FeatureSection, FeatureSectionProps } from './FeatureSection'
import Audience  from './Audience'
import Teaser from './SoonTeaser'
import Testimonials from './Testimonials'

export default function Home({ description = '' }) {
  return (
    <Flex flexDirection='column' flexGrow='1'>
      <Header />

      <Spacer />
      <Hero />

      <Container maxW='7xl'>
        <Heading textAlign='center' py={10}>
          How can we assist your fundraising?
        </Heading>
      </Container>
      <Features />
      {/* {featureSections.map((feature) => (
        <FeatureSection {...feature} />
      ))} */}

      <FeatureList />
      <Audience />
      {/* <Teaser/> */}
      <Testimonials />
      {/* <Features /> */}

      <Spacer />

      <Box>
        <Center bg='blue.500'>
          <Flex flexDirection='column' className='Main' flexGrow='1' mx={20} mt={10}>
            <Box mt={10} mb={20} className='content' flexGrow='1'>
              <Center mb={10} textAlign='center'>
                <Heading color='white' className='title'>
                  Already have access?
                </Heading>
              </Center>
              <Center>
                <Link href='/contacts' passHref>
                  <Button size='lg'>Log In</Button>
                </Link>
              </Center>
              <Center>
                <Text className='description'>{description}</Text>
              </Center>
            </Box>
          </Flex>
        </Center>
      </Box>
    </Flex>
  )
}

// const featureSections: FeatureSectionProps[] = [
//   {
//     title: 'Contact info that is always up-to-date',
//     subtitle:
//       'Outdated address books are a thing of the past. Benefit from data that is always up to date so you can get right to work without having to worry if your data is good.',
//     img: { src: 'https://res.cloudinary.com/dilbpof6u/image/upload/v1661963887/Home_Tab_ls3ewu.png', alt: 'screenshot' },
//     // button: { text: 'Request a Demo', href: '' },
//     // reverse: false
//   },
//   {
//     title: 'Powerful Automations',
//     subtitle: 'Power through your work with less friction.',
//     img: { src: 'https://res.cloudinary.com/dilbpof6u/image/upload/v1661963887/Home_Tab_ls3ewu.png', alt: 'screenshot' },
//     // button: { text: '', href: '' },
//     reverse: true,
//   },
//   {
//     title: 'Integrates with you',
//     subtitle:
//       'Integrations with industry standard formats ensure that you will be able to use and work with your data no matter where you are.',
//     img: { src: 'https://res.cloudinary.com/dilbpof6u/image/upload/v1661963887/Home_Tab_ls3ewu.png', alt: 'screenshot' },
//     // button: { text: '', href: '' },
//     // reverse: true
//   },
//   {
//     title: 'Powerful segmentation',
//     subtitle: 'Find connections with our social graph that combines 100+ data points on any contact or organization. ',
//     img: { src: 'https://res.cloudinary.com/dilbpof6u/image/upload/v1661963887/Home_Tab_ls3ewu.png', alt: 'screenshot' },
//     // button: { text: '', href: '' },
//     reverse: true,
//   },
// ]
